import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Switch,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { MailOutlined,DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';
import * as serverconfig from '../../views/serverconn'
import * as reactstrp from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});
var token= ''



class SendEmailForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    sacco:'',
    saccoobject:{},
    saccos:[],
    companyprofile:{},
    datasubmittedsuccessfully:false,
    datarequested:true,
    subaccount:'',
    messagemode:'',
    specialemail:'',
    emailmessage:'',
    fileattachment:null,
    attachfile:false,

  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){

    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
   }


    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/saccos/`)
    .then(res => {
        this.setState({
          saccos:res.data
        })

    })

    this.setState({datarequested:false})

  }

  

  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handlesaccochange= (e) =>{
    this.setState({ sacco: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/saccos/${e}`)
      .then(res => {  
          this.setState({saccoobject: res.data})
      })
  }


  handletransferedamountChange= (e) => this.setState({ transferedamount: e});
  handlemessagemodeChange= (e) => this.setState({ messagemode: e});
  handlespecialemailChange= (e) => this.setState({ specialemail: e.target.value});
  handleemailmessageChange= (e) => this.setState({ emailmessage: e.target.value});

  //submit the deposit now
  handleFormSubmit=(event) =>{

      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('date',moment().format(dateFormat).toString());
      form_data.append('sacco', this.state.sacco);
      form_data.append('messagemode', this.state.messagemode);
      form_data.append('specialemail', this.state.specialemail);
      form_data.append('emailmessage', this.state.emailmessage);
      form_data.append('subject', this.state.companyprofile.company_name);
      form_data.append('attachfile', this.state.attachfile);

      this.state.fileattachment==null?
      console.log("No profile file")
      :
      form_data.append('fileattachment', this.state.fileattachment, this.state.fileattachment?this.state.fileattachment.name:"");
  
      axios.post(serverconfig.backendserverurl+'/customqueries/sendcustomizedemail', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        console.log(res.data)
        this.setState({datarequested:false})
        message.info(res.data.message)
        window.location.reload(false)

      })
      .catch(error => console.log(error))
  }
  handleFileChange= (e) => this.setState({ fileattachment: e.target.files[0]});


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successful Message "
                    subTitle="Successfully sent SMS Message"
                    extra={[
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />
                    </Card>
                    </Col>
            </Row>

          </div>

        )

      }else{
        return(
          <div>
            <div>

                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Customized Email Form</h4>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                    >
                   
                    <FormItem 
                        label="Message Mode"
                        name="messagemode"
                        rules={[
                          {
                            required: true,
                            message: 'Please select message mode',
                          },
                        ]}
                        >
                            <Select 
                            value={this.state.messagemode} 
                            onChange={this.handlemessagemodeChange}
                            placeholder="Messaging Mode" >
                              <Option value='specialemail'>SPECIAL EMAIL ADDRESS</Option>
                              <Option value='specialclient'>SPECIAL CLIENT</Option>
                              <Option value='allclients'>ALL CLIENTS</Option>
                            </Select>
                          </FormItem>

                      {
                        this.state.messagemode==="specialclient"?
                        <FormItem 
                        name={'cient'}
                        label="Client"
                        rules={[
                          {
                            required: this.state.messagemode==="specialclient"?true:false,
                            message: 'Please select client!',
                          },
                        ]}
                        
                        >
                        <Select 
                        placeholder="Sacco/Microfinance" 
                        style={{  }} 
                        value={this.state.sacco}
                        onChange={this.handlesaccochange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                 
                          >
                            {this.state.saccos.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.sacco_name} </Option>
                              ))}
                          </Select>
                         </FormItem>
                        :
                        null
                      }

                      {

                      this.state.messagemode==="specialclient"?
                      <div>
                      <h5 style={{fontWeight:'bold',color:'#154360'}}>Client Email Details</h5>
                      <h5>Email Address: {this.state.saccoobject.company_email}</h5>
                      </div>
                      :
                      null
                      }

                      
                      {
                        this.state.messagemode==="specialemail"?
                        <FormItem 
                        label="Special Email"
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          },
                          {
                            required: this.state.messagemode==="specialemail"?true:false,
                            message: 'Please input your E-mail!',
                          },
                        ]}

                        >

                          <Input  
                          type="mail" 
                          prefix={<MailOutlined 
                          className="site-form-item-icon" />}  
                          placeholder="Put email here."
                          onChange={this.handlespecialemailChange}
                          />

                        </FormItem>    
                        :
                        null
                      }
                   

                      <FormItem label="Email Message"
                      name="emailmessage"
                      rules={[
                        {
                          required: true,
                          message: 'Please input email message',
                        },
                      ]}
                      
                      >
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(e,editor)=>{
                          this.setState({emailmessage:editor.getData()})

                        }}
                        data={"<h1><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; Email Body heading </strong></h1>"}
                        onInit={ editor => {
                          console.log( 'Editor is ready to use!', editor );
                        } }
                        >
                        </CKEditor>
 
                      </FormItem>

                      <FormItem  label="Attach File?" >
                      <Switch
                      checked={this.state.attachfile}
                      onChange={val=>{
                        this.setState({attachfile:!this.state.attachfile})
                      
                      }}
                      checkedChildren="YES"
                      unCheckedChildren="NO"
                      />
                      </FormItem>

                      {

                        this.state.attachfile?
                        <FormItem 
                        label="File Attachment" 
                        name="fileattachment"
                        rules={[
                          {
                            required: this.state.attachfile?true:false,
                            message: 'Please attachfile',
                          },
                        ]}
                       
                       >
                           <Input type="file" accept="*" placeholder="Attached File"  onChange={this.handleFileChange}/>
                       </FormItem> 

                        :
                        null

                      }


                    <FormItem>
                      <Button  type="primary" htmlType="submit">Send</Button>
                    </FormItem>
                    </Form>
  
                  </Card>
                   
            </div>
  
       </div>              
                      
        )
      }

    }
   
  }
}

export default SendEmailForm; 
