import React from 'react';
import axios from 'axios'
import { Table, Input,Spin,Tooltip,Button,Collapse,Avatar,Divider,Drawer,Descriptions,Popover,Popconfirm,message,Modal} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LockFilled,LockOutlined,PrinterOutlined,PlusSquareFilled } from '@ant-design/icons';
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../views/serverconn'
import SaccoUserCreationForm from '../components/saccoUserCreationForm'
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';

const { Panel } = Collapse;
const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

var token= ''


class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
   
    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile:res.data
        })

    })

  }


  render() {
    return (
      <div style={{padding:20}}>
          <Row > 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
              
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.company_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
                </Col>
           </Row>
          <br></br>
          <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><strong>{this.props.profile.sacco_name+" User Accounts"}</strong></h3>


          <Table columns={this.props.columns} 
          pagination={{hideOnSinglePage:true,responsive:true}} 
          dataSource={this.props.accounts}  
          bordered/>
         
      </div>
    );
  }
}

class SaccoUserList extends React.Component {
  state = {
    profile: {},
    searchText: '',
    searchedColumn: '',
    accounts: [],
    showprintermodal:false,
    showpasswordupdatemodal:false,
    selectedid:'',
    datarequested:true,
  };

  //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/accounts/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    const saccoId=this.props.match.params.saccoId;

    axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${saccoId}`)
    .then(res => {
        this.setState({
          accounts:res.data
        })

    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${saccoId}`)
    .then(res => {  
        this.setState({
            profile: res.data
        })

        this.setState({datarequested:false})

    })

}

displayusercreationmodal=()=>{
  this.setState({showprintermodal:true})

}


closeprintermodal=()=>{
  this.setState({showprintermodal:false})
}

displaypasswordupdatemodal=(id)=>{
  this.setState({showpasswordupdatemodal:true})
  this.setState({selectedid:id})
}

closepasswordupdatemodal=()=>{
  this.setState({showpasswordupdatemodal:false})
}

//////////////////////////////////////
///// change password here
handlePasswordChange=(values)=>{
  this.setState({datarequested:true})

  let form_data = new FormData();
  form_data.append('password1', values.new_password1);
  form_data.append('password2', values.new_password2);
  form_data.append('userid', this.state.selectedid);

  //Now send the post resquest
    axios.post(serverconfig.backendserverurl+'/customqueries/updatecompanyuserpassword', form_data, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
    this.setState({datarequested:false})

    window.location.reload(false);

    message.info(res.data.message);
  }
  )
  .catch(error => console.log(error)) 

}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [
      {
        title: 'Profile',
        dataIndex: 'profile_pic',
        key: 'profile_pic',
        render: text => <Avatar
        size={60}
        icon={<UserOutlined />}
        alt="User Pic"
        src={text}
        />,

      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'id',
        ...this.getColumnSearchProps('username'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'id',
      },
      {
        title: 'Manager?',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) => 
        <p>
        {
          record.branch_manager==true?
          <p>Yes</p>
          :
          <p>No</p>
        }
        </p>

      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/saccouserdetails/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click to change password</p>} title="Password Change">
          <LockFilled style={{color:'#154360'}} onClick={() => this.displaypasswordupdatemodal(text)}/>
        </Popover>

        </p>,
      },

    ];

    const printcolumns = [
      {
        title: 'Profile',
        dataIndex: 'profile_pic',
        key: 'profile_pic',
        render: text => <Avatar
        size={60}
        icon={<UserOutlined />}
        alt="User Pic"
        src={text}
        />,

      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'id',
        ...this.getColumnSearchProps('username'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'id',
      },
      {
        title: 'Manager?',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) => 
        <p>
        {
          record.branch_manager==true?
          <p>Yes</p>
          :
          <p>No</p>
        }
        </p>

      },
     

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{
      return(
        <div>
           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h5>{this.state.profile.sacco_name+" | "+this.state.profile.branchname+" User Accounts"}</h5>} key="1">
            <Table 
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.accounts}
             scroll={{ x: 1000 }}
             bordered/>
            </Panel>
            </Collapse>
            <br></br>
            <div style={{overflowX:'auto'}}>
            <reactstrp.Table>
              <tbody>
              <tr>
              
                <td>
                <div>
                  <Tooltip title="Click to add New User" placement="top">
                  <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><PlusSquareFilled style={{ fontSize: '40px', color: '#08c' }} onClick={() => this.displayusercreationmodal()}/></p>
                  <h6 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>Create New User</h6>
                  </Tooltip>
                </div>
                </td>
                <td>
                <a>
                    <ReactToPrint
                      trigger={() =>                     
                        <div>
                          <Tooltip title="Click to print company users" placement="top">
                          <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                          <h6 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>Print</h6>
                          </Tooltip>
                        </div>                          
                    }
                    content={() => this.componentRef}
                  />  
                  </a>
                </td>
  
              </tr>
            
            </tbody>
          </reactstrp.Table>             
            </div>

            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            columns={printcolumns}
            accounts={this.state.accounts}
            profile={this.state.profile}
            ref={el => (this.componentRef = el)} /> 
             </div>
           
            <Modal              
                visible={this.state.showprintermodal}
                title="Sacco User Registration"
                width={600}
                onCancel={this.closeprintermodal}

                footer={[
                  <Button key="back" onClick={this.closeprintermodal}>
                    Cancel
                  </Button>
                  ]}
                  >
                <SaccoUserCreationForm saccoId={this.props.match.params.saccoId} />
        
            </Modal>



            <Modal              
                visible={this.state.showpasswordupdatemodal}
                title="Password change Form"
                width={600}
                onCancel={this.closepasswordupdatemodal}

                footer={[
                  <Button key="back" onClick={this.closepasswordupdatemodal}>
                    Cancel
                  </Button>
                  ]}
                  >
              <Form
              onFinish={this.handlePasswordChange}        
              name="passwordreset"
              className="login-form">
        
              <Form.Item
                  name="new_password1"
                  label="New Password one"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password one"
                  />


                </Form.Item>

          
                <Form.Item
                  name="new_password2"
                  label="Confirm New Password"
                  dependencies={['new_password1']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('new_password1') === value) {
                          return Promise.resolve();
                        }
          
                        return Promise.reject('The two passwords that you entered do not match!');
                      },
                    }),
                  ]}
                >
                  <Input.Password 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Confirm" />

                </Form.Item>

              <Form.Item >
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Confirm Change
                  </Button>
              </Form.Item>
            </Form>
                    
            </Modal>

        </div>
    )

    }
    
  }
}

export default SaccoUserList; 
