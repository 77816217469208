import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import 'antd/dist/antd.css'; 
//import 'antd/dist/result.css';

import { connect } from 'react-redux'
import * as actions from './store/actions/auth'
import { BrowserRouter as Router } from 'react-router-dom'

import LoginLayout from './views/containers/LoginLayout'
import Login from './views/containers/Login';

import AdminBaseRouter from './adminroutes'
import SuperUserLayout from './SiteAdmin/containers/superuserlayout'

class App extends Component {

  componentDidMount(){
    this.props.onTryAutoSignup();
  }

  render() {
    return (
      this.props.isAuthenticated ?
        <Router>
          <SuperUserLayout {...this.props}>
            <AdminBaseRouter/>
          </SuperUserLayout>
        </Router>
      :
        <Router>
        <LoginLayout>
          <Login />
        </LoginLayout>
        </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.token !== null
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
