import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Avatar,Spin,Image,Modal,Input,message,Select,Checkbox,Switch } from 'antd';
import CompanyProfileForm from '../components/CompanyProfileForm'
import { UserOutlined } from '@ant-design/icons';
import { PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import ImgsViewer from 'react-images-viewer'
import PhoneInput from 'react-phone-number-input'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import {
    Col,
    Row,
  } from 'reactstrap';

const FormItem=Form.Item;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'HHmmssDDMMYYYY';

var token= ''


class SuperCompanyDetail extends React.Component{

    state={
        companyname:'',  
        licence:'',
        companyemail:'',
        companylogo:null,   
        companyphone:'',
        boxnumber:'',
        companyphonetwo:'',
        companyphonethree:'',
        datarequested:true,  
        profile:{},
        packages:[],
        package:'',
    }


    componentDidMount(){
        if(localStorage.getItem("token")){
            token= localStorage.getItem("token")
         }else{
            token= ''
         }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        //component mount taks
        this.componentmounttasks();

    }

    //component mount tasks
    componentmounttasks=()=>{

        const supercompany=this.props.match.params.supercompanyid;
        axios.get(`${serverconfig.backendserverurl}/api/supercompanies/${supercompany}`)
        .then(res => {  
            this.setState({
                profile: res.data
            })

            this.setState({companyname:res.data.name})
            this.setState({companyemail:res.data.company_email})
            this.setState({companyphone:res.data.RegisteredPhoneNumber1})
            this.setState({boxnumber:res.data.box_number})
            this.setState({companyphonetwo:res.data.RegisteredPhoneNumber2})
            this.setState({companyphonethree:res.data.RegisteredPhoneNumber3})
          
            this.setState({datarequested:false});

        })


    }


    handleCompanyNameChange= (e) => this.setState({ companyname: e.target.value});
    handleCompanyEmailChange= (e) => this.setState({ companyemail: e.target.value});
    handleCompanyLogoChange= (e) => this.setState({ companylogo: e.target.files[0]});
    handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
    handleCompanyBoxNumberChange= (e) => this.setState({ boxnumber: e.target.value});
    handleCompanyPhonetwoChange= (e) => this.setState({ companyphonetwo: e});
    handleCompanyPhonethreeChange= (e) => this.setState({ companyphonethree: e});
    



    //submit button pressed
    handleFormSubmit=() =>{
        const supercompany=this.props.match.params.supercompanyid;

        this.setState({datarequested:true})

        let form_data = new FormData();
        form_data.append('name', this.state.companyname);
        form_data.append('company_email', this.state.companyemail);
        form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
        form_data.append('box_number', this.state.boxnumber);
        this.state.companylogo==null?
        console.log("No profile file")
        :
        form_data.append('logo', this.state.companylogo,this.state.companylogo?this.state.companylogo.name:"");
        
        form_data.append('RegisteredPhoneNumber2', this.state.companyphonetwo);
        form_data.append('RegisteredPhoneNumber3', this.state.companyphonethree);

          axios.put(serverconfig.backendserverurl+`/api/supercompanies/${supercompany}/`, form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res =>{
            this.setState({datarequested:false})
            message.info('Successfully updated head company')
            this.componentmounttasks();
        }
        )
        .catch(error => console.log(error))

    }


    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
          }else{
            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="6">
                    <Card title={<h3 style={{fontWeight:'bolder'}}>{this.state.profile.name}</h3> }>
                        <p>Email: {this.state.profile.company_email}</p>
                        <p>Phone: {this.state.profile.RegisteredPhoneNumber1}</p>
                        <p>Phone 2: {this.state.profile.RegisteredPhoneNumber2}</p>
                        <p>Phone 3: {this.state.profile.RegisteredPhoneNumber3}</p>
                        <p>Box No: {this.state.profile.box_number}</p>
                        
                    </Card>
                    </Col>
    
                    <Col xs="12" sm="6" lg="6">
                      <Card 
                            style={{padding:10,alignContent:'center',alignItems:'center'}}
                            hoverable
                            cover={
                            
                                <Image
                                width={100}
                                //height={'50%'}
                                src={this.state.profile.logo}    
                                />                   
                             }
                        >
                        </Card>
                    </Col>
                   
                    </Row>
                    <br/>
    
                    <Row>
                    <Col xs="12" sm="6" lg="6">
                    <Card 
                            style={{padding:10}}
                            hoverable>
    
                        <h4>Sacco Profile Update</h4>
                        <Form
                            >
                                <FormItem label="Name"
                   
                                >
                                <Input name="sacconame"  placeholder="Put a name here." value={this.state.companyname} onChange={this.handleCompanyNameChange} />
                                </FormItem>
                    
                                <FormItem label="Logo">
                                <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo"  onChange={this.handleCompanyLogoChange}/>
                                </FormItem>  
                                
                                <FormItem label="Email"
                 
                                >
                                <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.companyemail} onChange={this.handleCompanyEmailChange} />
                                </FormItem> 
                    
                                <FormItem label="Phone Number">
                                <PhoneInput
                                    countrySelectProps={{ unicodeFlags: true }}
                                    defaultCountry="UG"
                                    placeholder="Enter phone number"
                                    value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
                                </FormItem>
                                <FormItem label="Phone Number Two">
                                <PhoneInput
                                    countrySelectProps={{ unicodeFlags: true }}
                                    defaultCountry="UG"
                                    placeholder="Enter phone number"
                                    value={this.state.companyphonetwo} onChange={this.handleCompanyPhonetwoChange}/>
                                </FormItem> 
                    
                                <FormItem label="Phone Number Three">
                                <PhoneInput
                                    countrySelectProps={{ unicodeFlags: true }}
                                    defaultCountry="UG"
                                    placeholder="Enter phone number"
                                    value={this.state.companyphonethree} onChange={this.handleCompanyPhonethreeChange}/>
                                </FormItem>  

                    
                                <FormItem label="Box number">
                                <Input name="boxnumber"  placeholder="Box number here" value={this.state.boxnumber} onChange={this.handleCompanyBoxNumberChange} />
                                </FormItem>
                    
                            <FormItem>
                            <Button type="primary" htmlType="button" onClick={(event) => this.handleFormSubmit()}>Update</Button>
                            </FormItem>
                            </Form>
                    </Card>
                       
    
                    </Col>
                
                    </Row>
    
                    <br/>
    
                </div>
            )

          }
     
    }
}

export default SuperCompanyDetail; 