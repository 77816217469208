
import React from 'react'
import { connect } from 'react-redux'
import { Layout,Avatar, Menu, Breadcrumb,Button,Row, Col, Divider,Card,Dropdown,Switch,
    message, Tooltip,InputNumber,Modal,Image} from 'antd';
import { Drawer, List, } from 'antd';

import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LaptopOutlined,
  NotificationOutlined,
  SettingOutlined,
  AuditOutlined,
  IdcardOutlined,
  BarsOutlined,
  ShopOutlined,
  DollarOutlined,
  PicLeftOutlined,
  LogoutOutlined,
  EditOutlined, 
  EllipsisOutlined,
  HomeOutlined,
  SnippetsOutlined,
  KeyOutlined,
  FilePdfFilled,
  RightCircleFilled,
  DollarCircleOutlined,
  AccountBookOutlined,
  FilePptOutlined,
  PayCircleOutlined,
  BarChartOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  BankFilled,
  AndroidOutlined,
  DeploymentUnitOutlined,
  GroupOutlined
} from '@ant-design/icons';

import { NavLink } from 'react-router-dom'
import { PageHeader, Descriptions } from 'antd';
import { Link,withRouter} from 'react-router-dom';
import * as actions from '../../store/actions/auth'
import axios from 'axios'
import UserAccountUpdateForm from '../components/UserAccountUpdateForm'
import WrappedPasswordResetForm from './PasswordResetForm'
import * as serverconfig from '../../views/serverconn'
import logo from '../../assets/Pitech-logoconv.png';
import IdleTimer from 'react-idle-timer';

var CryptoJS = require("crypto-js");


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const style = { background: '#0092ff', padding: '8px 0' };
const { Meta } = Card;

const pStyle = {
  fontSize: 16,
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};

const DescriptionItem = ({ title, content }) => (
  <div
    className="site-description-item-profile-wrapper"
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
    }}
  >
    <p
      className="site-description-item-profile-p"
      style={{
        marginRight: 8,
        display: 'inline-block',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

var bizuserid= ''

var token= ''

class SaccoLayout extends React.Component{

  state = {
    collapsed: false,
    theme: 'dark',
    current: '1',
    titlecolor: '#fff',
    titlevisibility:"visible",
    userprofile:{},
    companyprofile:{},
    isToggle: null,
    visible: false,
    resetmodalvisible:false,
    updateformvisible:'none',
    userrights:{},
    isTimedOut: false,
    showtimeoutmodal:false

  };

  // show the profile modal
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  // update user profile
  showUpdateForm=()=>{
    this.state.updateformvisible=="none"?
    this.setState({
      updateformvisible: "block",
    })
    :
    this.setState({
      updateformvisible: "none",
    })    
  }


  /////////////////////////////////////////////
  //PASSWORD RESET MODAL METHODS BEGIN HERE

  showpasswordresetModal = () => {
    this.setState({
      resetmodalvisible: true,
    });
  };

  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ resetmodalvisible: false });

  };


//component did mount
  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       bizuserid= ''
    }


    let form_data = new FormData();
    form_data.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
     
    })

    //get the company profile here
    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

  }

  //method for changing color theme 
  changeTheme = value => {
    this.setState({
      theme: value ? 'dark' : 'light',
    });

    this.setState({
      titlecolor: value ? '#fff' : '#A9A9A9',
    });
    
  };

  //method for collupsing of the menu
  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
    this.setState({titlevisibility: collapsed ? "hidden" :"visible"});
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });

    this.setState({titlevisibility:"hidden"});

  };


   menu = (
    <Menu >
      <Menu.Item key="1" onClick={this.props.logout}>
        <LogoutOutlined />
        Log out
      </Menu.Item>
      <Menu.Item key="2" onClick={this.showDrawer}>
        <UserOutlined />
        View Profile
      </Menu.Item>
      <Menu.Item key="3" onClick={this.showpasswordresetModal}>
        <KeyOutlined />
        Change Password
      </Menu.Item>
    </Menu>
  );

   render(){
    return (
      <div>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={()=>{}}
            onIdle={()=>{
              this.props.logout()

              /*
              console.log("AM IDLE")

              const isTimedOut = this.state.isTimedOut

              if (isTimedOut) {
              } else {
                  this.setState({showtimeoutmodal: true})
                  this.idleTimer.reset();
                  this.setState({isTimedOut: true})
                }
*/
            }}
            onAction={()=>{}}
            debounce={250}
            timeout={this.state.companyprofile.sessiontimeoutmilliseconds} />

        {
         this.props.isAuthenticated ?
         <Layout style={{ minHeight: '100vh' }} >
         <Sider
          collapsedWidth="0"
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse} 
          theme={this.state.theme}
          breakpoint="lg"
          onBreakpoint={broken => {
            console.log(broken);
          }}
          zeroWidthTriggerStyle={{display:'none'}}
          >
            <br></br>
           <div className="logo" style={{ paddingLeft: 30}} mode="inline">
           <Row>

             <Col span={6}>
{/*               <Avatar size={40} icon={<UserOutlined />} alt="Logo" src={this.state.companyprofile.company_logo} />
        */}               
             <Image
              width={50}
              height={50}
              src={logo}    
              />
             </Col>
{/*             <Col span={18}><NavLink to='/'><h5 style={{color:this.state.titlecolor,paddingTop: 10,visibility:this.state.collapsed?"hidden":"visible" }}>{this.state.companyprofile.company_name}</h5></NavLink></Col>
      */}           </Row>
           <br/>
           </div>
   
           <Menu 
           theme={this.state.theme}
           onClick={this.handleClick}
           defaultSelectedKeys={['1']} 
           mode="inline">

            <SubMenu
               key="sub1"
               title={
                 <span>
                   <HomeOutlined />
                   <span>Home</span>
                 </span>
               }
             >
            <Menu.Item key="1" >
              <RightCircleFilled />
              <NavLink to='/admin/'><span>Home</span></NavLink>

            </Menu.Item>

            <Menu.Item key="2" >
              <RightCircleFilled />
              <NavLink to='/applicationdetails/'><span>System Profile</span></NavLink>
            </Menu.Item>
            </SubMenu>

            <SubMenu
            key="sub2"
            title={
              <span>
                <GroupOutlined />
                <span>Companies</span>
              </span>
            }
          >
            <Menu.Item key="3"><NavLink to='/supercompanies/'> <RightCircleFilled />Head Companies</NavLink></Menu.Item>
            <Menu.Item key="4"><NavLink to='/saccos/'> <RightCircleFilled /> Branches</NavLink></Menu.Item>
            <Menu.Item key="5"><NavLink to='/packages/'> <RightCircleFilled /> Packages</NavLink></Menu.Item>

           </SubMenu>

          <SubMenu
            key="sub5"
            title={
              <span>
                <FileOutlined />
                <span>Reports</span>
              </span>
            }
          >
            <Menu.Item key="6"><NavLink to='/vendorwalletstatement/'> <RightCircleFilled />Sacco Wallet </NavLink></Menu.Item>
            <Menu.Item key="7"><NavLink to='/smsusagestatement/'> <RightCircleFilled />Sacco SMS </NavLink></Menu.Item>

          </SubMenu>    


           </Menu>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
           <br></br>
              <Switch
                 checked={this.state.theme === 'dark'}
                 onChange={this.changeTheme}
                 checkedChildren="Dark"
                 unCheckedChildren="Light"
               />
           <br></br>
           </div>

         </Sider>
         <Layout className="site-layout">
   
 
            <PageHeader
              style={{backgroundColor: "white"}}
              ghost={true}
              tags={React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {className: 'trigger',
              onClick: this.toggle,
            })}
              extra={[
                <Avatar size={30} icon={<UserOutlined />} alt="User Pic" src={serverconfig.backendserverurl+this.state.userprofile.profile_pic} />,
                <Dropdown.Button overlay={this.menu}>
                Hi, {this.state.userprofile.username}
                </Dropdown.Button>
              ]}
              >
          </PageHeader>
        


           <Content style={{ margin: '0 16px' }}>
             <br></br>
             <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
             {this.props.children}
             </div>
           </Content>
           <Footer style={{ textAlign: 'center' }}>System Design ©2021 Created by PI-Tech</Footer>
         </Layout>
       </Layout>
         :
         <Layout style={{ minHeight: '100vh' }}>
         <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
           <div className="site-layout-background" style={{padding: 24, minHeight: 380,display: 'flex',  justifyContent:'center', alignItems:'center' }}>
             {this.props.children}
           </div>
         </Content>
         <Footer style={{ textAlign: 'center' }}>System Design ©2021 Created by PI-Tech</Footer>
         </Layout>
        }

        <Drawer
          title="Account Profile"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Avatar
              size={100}
              icon={<UserOutlined />}
              alt="client logo"
              src={serverconfig.backendserverurl+this.state.userprofile.profile_pic}
              />
          </p>
          <Row>
            <Col span={12}>
              <DescriptionItem title="User Name" content={this.state.userprofile.username} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Email" content={this.state.userprofile.email} />
            </Col>
          </Row>         
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Button type="primary" onClick={this.showUpdateForm}>
               {
                 this.state.updateformvisible=="none"?
                 "Open Update Form"
                 :
                 "Close Update Form"
               }
               
               </Button>
          </p>
          
          <div style={{display:this.state.updateformvisible}}>
           <UserAccountUpdateForm accountID={this.state.userprofile.id} username={this.state.userprofile.username} email={this.state.userprofile.email}/>       

          </div>

        </Drawer>


        <Drawer
          title="Password Change Form"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onresetdrawerClose}
          visible={this.state.resetmodalvisible}
        >
            <WrappedPasswordResetForm />


        </Drawer>

        <Modal              
          visible={this.state.showtimeoutmodal}
          title={<h3>You Have Been Idle!</h3>}
          width={600}
          onCancel={()=>{
            this.setState({showtimeoutmodal:false})
          }}
      
          footer={[
            <Button key="back" type='default' onClick={()=>{
              this.props.logout()
            }}>
              Logout
            </Button>,

            <Button type='primary' key="back" onClick={()=>{this.setState({showtimeoutmodal:false})}}>
            Stay
            </Button>

            ]}
            >
              
              <h5>You Will Get Timed Out. You want to stay?</h5>
                   
      </Modal>

      </div>
  
  );

  }

    
}


const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(
      actions.logout(),
      )
  }
}


export default withRouter(connect(null,mapDispatchToProps)(SaccoLayout)) ;

  
