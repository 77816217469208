import React from 'react';
import { Form, Input, Button,Select,Checkbox,Upload,message,Spin } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import * as serverconfig from '../../views/serverconn'

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

if(localStorage.getItem("token")){
  var token= localStorage.getItem("token")
}else{
  var token= ''
}

class UserAccountUpdateForm extends React.Component {
  formRef = React.createRef();

  state = {
    profilepic:null,   
    profileuploadname:"",
    datarequested:false,  

  }

  componentDidMount(){
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.formRef.current.setFieldsValue({
      username: this.props.username,
      email: this.props.email,
    });
  }


  //submit button pressed
  onFinish=values =>{
    this.setState({datarequested:true})

    this.state.profilepic==null?
    this.state.profileuploadname=""
    :
    this.state.profileuploadname=this.state.profilepic.name


    let form_data = new FormData();
    form_data.append('username', values.username);
    form_data.append('email', values.email);
    form_data.append('is_active', true);

    this.state.profilepic==null?
     console.log("No profile file")
     :
     form_data.append('profile_pic', this.state.profilepic,this.state.profileuploadname);


    //Now send the post resquest
      axios.put(`${serverconfig.backendserverurl}/api/accounts/${this.props.accountID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res =>{
      this.setState({datarequested:false})

      message.info('User Profile Updated successfully');
      localStorage.setItem('username',values.username);
      window.location.reload(false);
    } 
    )
    .catch(error => console.log(error)) 

    };

     normFile = e => {
      console.log('Upload event:', e);
    
      if (Array.isArray(e)) {
        return e;
      }
    
      return e && e.fileList;
    };


    handleprofilepicChange= (e) => this.setState({ profilepic: e.target.files[0]});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
          <Form  
          onFinish={this.onFinish}        
          ref={this.formRef}>
  
            <FormItem 
            name="username"
            label="User Name"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Put a name here." defaultValue={this.props.username} />
            </FormItem>
  
            <FormItem 
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
            >
              <Input  type="mail" prefix={<MailOutlined className="site-form-item-icon" />}  placeholder="Put a name here." defaultValue={this.props.email}  />
            </FormItem>
  
            <FormItem label="Profile Image"
            name="profilepic">
                <Input  type="file" accept="image/png, image/jpeg" placeholder="Profile Image" onChange={this.handleprofilepicChange}/>
            </FormItem>
  
            <FormItem>
              <Button htmlType="submit" type="primary">Update</Button>
            </FormItem>
          </Form>
  
        </div>
        
      );

    }



   
  }

}

export default UserAccountUpdateForm;



