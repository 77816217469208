import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../../views/serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''


class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
     }else{
        token= ''
     }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }


      axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

  
    }


    render() {
      return (
        <div style={{padding:20}}>
          <Row > 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
              
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.company_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
                </Col>
           </Row>
             <br></br> 
             <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',strong:true}}>{this.props.sacconame} Wallet Statement from {this.props.dateone}  to {this.props.datetwo}</h2>

             <reactstrp.Table bordered>
             <thead>
                <tr>
                  <th>Date</th>
                  <th>Account No</th>
                  <th>Withdraw Phone</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
              {this.props.reportitems.map(
                (item)=>(
                  <tr>
                  <td>{item.date}</td>
                  <td>{item.accountnumber}</td>
                  <td>{item.withdrawcontact}</td>
                  <td>{item.debit}</td>
                  <td>{item.credit}</td>
                  <td>{item.walletbalance}</td>
                  </tr>
                ))}
                 <tr>                                      
                  <th>Totals</th>                                    
                  <th></th>  
                  <td></td> 
                  <th><strong><CurrencyFormat value={this.props.totaldebit} displayType={'text'} thousandSeparator={true}  /> </strong></th>
                  <th>
                  <h4><strong><CurrencyFormat value={this.props.totalcredit} displayType={'text'} thousandSeparator={true}  /> </strong> </h4>
                  </th>  
                  <th></th>                    
                  </tr>  
              </tbody>
            </reactstrp.Table>
        </div>
      );
    }
  }


class Saccowalletstatementreport extends React.Component {

  state = {
    saccos:[],
    reportitems:[],
    dateone:'',
    datetwo:'',
    sacco:'',
    sacconame:'',
    totalcredit:0,
    totaldebit:0,
    datarequested:true,
    country:'',
    currency:''
   };

   componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/saccos/`)
    .then(res => {
        this.setState({
          saccos:res.data
        })
        this.setState({datarequested:false})

      })

  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 

  handlesaccoChange= (e) =>{
    this.setState({ sacco: e});
    //get the vendor details
    axios.get(`${serverconfig.backendserverurl}/api/saccos/${e}`)
        .then(res => {  
            this.setState({
                sacconame: res.data.sacco_name
            })
         
        })

  } 

 
   //search sales method
   searchSales=()=>{
    this.setState({reportitems:[]})
    this.setState({totalcredit:0})
    this.setState({totaldebit:0})
    
     //set loading state
     this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('sacco', this.state.sacco);

    console.log(this.state.sacco)
      
    if(this.state.vendor===''){
      alert("Please select sacco")
    }else{

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/getsaccowalletstatement', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res =>{
           this.setState({datarequested:false})
            this.setState({reportitems:JSON.parse(res.data.report)})
            this.setState({totalcredit:res.data.totalcredit})
            this.setState({totaldebit:res.data.totaldebit})
        } 
        )
        .catch(error => console.log(error))   
    }

    }

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
       
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
      },   
      {
        title: 'Account Number',
        dataIndex: 'accountnumber',
        key: 'id',
      }, 
      {
        title: 'Withdraw Phone',
        dataIndex: 'withdrawcontact',
        key: 'id',
      },        
      {
        title: 'Debit',
        dataIndex: 'debit',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'Credit',
        dataIndex: 'credit',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
 
      {
        title: 'Balance',
        dataIndex: 'walletbalance',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },

    ];
 
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <div style={{display: "none"}}>
             &nbsp;&nbsp;
            <ReportToPrint
            totalcredit={this.state.totalcredit} 
            totaldebit={this.state.totaldebit} 
            dateone={this.state.dateone}
            datetwo={this.state.datetwo}
            columns={columns}
            reportitems={this.state.reportitems}
            sacconame={this.state.sacconame}
            currency={this.state.currency}
            country={this.state.country}

            ref={el => (this.componentRef = el)} /> 
          </div>

          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="SACCOS WALLET STATEMENT REPORT" key="1">
            <Form  layout="inline" >

            <FormItem label="Sacco">
                   <Select 
                   placeholder="Sacco"
                   value={this.state.sacco} 
                   onChange={this.handlesaccoChange}
                   style={{width:300}}                                       
                   showSearch
                   optionFilterProp="children"
                   onFocus={this.onFocus}
                   onBlur={this.onBlur}
                   onSearch={this.onSearch}
                   filterOption={(input, option) =>
                     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                   }   
                    >
                     {this.state.saccos.map(
                       (itm)=>(
                         <Option value={itm.id}>{itm.sacco_name}</Option>
                       ))}
                   </Select>
               </FormItem>


                <FormItem label="Date Range">
                    <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                </FormItem>

                <FormItem>
                <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
                </FormItem> 

           </Form>

           <br></br>
            <Table columns={columns} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.reportitems}
            scroll={{ x: 1000 }}
           bordered/>  
            <br></br> 
            <Divider style={{borderColor:'red'}} dashed />

            <div style={{overflowX:'auto'}}>
            <Space>

               <h4>TOTAL DEBIT: <CurrencyFormat value={this.state.totaldebit} displayType={'text'} thousandSeparator={true}  />  </h4>
                &nbsp;&nbsp;

                &nbsp;&nbsp;
                <h4>TOTAL CREDIT: <CurrencyFormat value={this.state.totalcredit} displayType={'text'} thousandSeparator={true}  />   </h4>
                &nbsp;&nbsp;

                <ReactToPrint
                trigger={() => 
                <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                Print
                </Button>
                }
                content={() => this.componentRef}
                />
            </Space>
           </div>

            </Panel>
            </Collapse>


        </div>

    )
    }
 
     
   }
}

export default Saccowalletstatementreport; 
