import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Avatar,Spin,Image,Modal,Input,message,Select,Checkbox,Switch } from 'antd';
import CompanyProfileForm from '../components/CompanyProfileForm'
import { UserOutlined } from '@ant-design/icons';
import { PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import ImgsViewer from 'react-images-viewer'
import PhoneInput from 'react-phone-number-input'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import {
    Col,
    Row,
  } from 'reactstrap';

const FormItem=Form.Item;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'HHmmssDDMMYYYY';

var token= ''


class SaccoDetail extends React.Component{

    state={
        companyname:'',  
        licence:'',
        companyemail:'',
        companylogo:null,   
        companyphone:'',
        boxnumber:'',
        accountslimit:0,
        branchname:'',
        companyphonetwo:'',
        companyphonethree:'',
        website:'',
        address:'',
        datarequested:true,  
        safebalance:0,
        sharevalue:0,
        retainedearnings:0,
        profile:{},
        activated:false,
        usesms:false,
        packages:[],
        package:'',
        roundoffvalue:0,
        sharetransfercharge:0,
        smsmessages:0,

        showwelfare:false,
        showemergency:false,
        showdream:false,
        showlegacy:false,
        showchildren:false,
        showuniversity:false,
        showsubaccounttransfer:false,

        automatedloanrepayment:false,
        smsonautomatedloanrepayment:false,
        loanrepaymentalertdays:0,
        automatedloanreminders:false,
        dataidentificationcode:'',
        showgroupaccounts:false,

        showeditablewithdrawcharge:false,
        sharesfeature:false,
        userbalancesfeature:false,
        interbranchtransactionsactivated:false,

        mobilemoneygatewaycode:'',
        email_statements:false,
        attach_officer_to_loan:false,

        automated_accounts:false,
        account_concstring:'',
        last_accountnmumber:0,

        show_daily_loan_schedule:false,
        exclude_sundays_on_daily_loan_schedule:false,
        exclude_saturdays_on_daily_loan_schedule:false,
        automated_dailyloanrepaymentondeposit:false,

        noofshares:0,
        sharecapital:0,
        dividend_sharable_percentage:0,

        increase_in_no_ofshares:false,
        change_sharevalue:false,
        restrictuser_to_entered_accounts_app:false,

        salary_requisition_allowed:false,
        allow_transaction_editing:false

    }


    componentDidMount(){
        if(localStorage.getItem("token")){
            token= localStorage.getItem("token")
         }else{
            token= ''
         }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        //component mount taks
        this.componentmounttasks();

    }

    //component mount tasks
    componentmounttasks=()=>{

        const saccoId=this.props.match.params.saccoId;
        axios.get(`${serverconfig.backendserverurl}/api/saccos/${saccoId}`)
        .then(res => {  
            this.setState({
                profile: res.data
            })

            this.setState({companyname:res.data.sacco_name})
            this.setState({branchname:res.data.branchname})
            this.setState({companyemail:res.data.company_email})
            this.setState({companyphone:res.data.RegisteredPhoneNumber1})
            this.setState({boxnumber:res.data.box_number})
            this.setState({accountslimit:res.data.accountslimit})
            this.setState({companyphonetwo:res.data.RegisteredPhoneNumber2})
            this.setState({companyphonethree:res.data.RegisteredPhoneNumber3})
            this.setState({website:res.data.website})
            this.setState({activated:res.data.activated})
            this.setState({address:res.data.address})
            this.setState({safebalance:res.data.safe_balance})
            this.setState({sharevalue:res.data.sharevalue})
            this.setState({retainedearnings:res.data.retained_earnings})
            this.setState({usesms:res.data.usesms})
            this.setState({licence:res.data.licencedays})
            this.setState({package:res.data.package})
            this.setState({roundoffvalue:res.data.roundoffvalue})
            this.setState({sharetransfercharge:res.data.sharetransfercharge})

            this.setState({showwelfare:res.data.showwelfare})
            this.setState({showemergency:res.data.showemergency})
            this.setState({showdream:res.data.showdream})
            this.setState({showlegacy:res.data.showlegacy})
            this.setState({showchildren:res.data.showchildren})
            this.setState({showuniversity:res.data.showuniversity})
            this.setState({showsubaccounttransfer:res.data.showsubaccounttransfer})
            this.setState({smsmessages:res.data.smsmessages})


            this.setState({automatedloanrepayment:res.data.automatedloanrepayment})
            this.setState({smsonautomatedloanrepayment:res.data.smsonautomatedloanrepayment})
            this.setState({loanrepaymentalertdays:res.data.loanrepaymentalertdays})
            this.setState({automatedloanreminders:res.data.automatedloanreminders})
            this.setState({dataidentificationcode:res.data.dataidentificationcode})
            this.setState({showgroupaccounts:res.data.showgroupaccounts})

            this.setState({showeditablewithdrawcharge:res.data.showeditablewithdrawcharge})
            this.setState({sharesfeature:res.data.sharesfeature})
            this.setState({userbalancesfeature:res.data.userbalancesfeature})

            this.setState({interbranchtransactionsactivated:res.data.interbranchtransactionsactivated})

            this.setState({mobilemoneygatewaycode:res.data.mobilemoneygatewaycode})

            this.setState({email_statements:res.data.email_statements})
            this.setState({attach_officer_to_loan:res.data.attach_officer_to_loan})

            this.setState({automated_accounts:res.data.automated_accounts})
            this.setState({account_concstring:res.data.account_concstring})
            this.setState({last_accountnmumber:res.data.last_accountnmumber})

            this.setState({show_daily_loan_schedule:res.data.show_daily_loan_schedule})
            this.setState({exclude_sundays_on_daily_loan_schedule:res.data.exclude_sundays_on_daily_loan_schedule})
            this.setState({exclude_saturdays_on_daily_loan_schedule:res.data.exclude_saturdays_on_daily_loan_schedule})

            this.setState({automated_dailyloanrepaymentondeposit:res.data.automated_dailyloanrepaymentondeposit})

            this.setState({noofshares:res.data.noofshares})
            this.setState({sharecapital:res.data.sharecapital})
            this.setState({dividend_sharable_percentage:res.data.dividend_sharable_percentage})

            this.setState({increase_in_no_ofshares:res.data.increase_in_no_ofshares})
            this.setState({change_sharevalue:res.data.change_sharevalue})
            this.setState({restrictuser_to_entered_accounts_app:res.data.restrictuser_to_entered_accounts_app})

            this.setState({salary_requisition_allowed:res.data.salary_requisition_allowed})
            this.setState({allow_transaction_editing:res.data.allow_transaction_editing})



            

        })

        axios.get(serverconfig.backendserverurl+'/api/packages/')
        .then(res => {
            this.setState({
              packages:res.data
            })
    
            this.setState({datarequested:false});
        })
    }


    handleCompanyNameChange= (e) => this.setState({ companyname: e.target.value});
    handleLicenceChange= (e) => this.setState({ licence: e.target.value});
    handleCompanyEmailChange= (e) => this.setState({ companyemail: e.target.value});
    handleCompanyLogoChange= (e) => this.setState({ companylogo: e.target.files[0]});
  
    handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
    handleCompanyBoxNumberChange= (e) => this.setState({ boxnumber: e.target.value});
    handleaccountslimitChange=(e) => this.setState({ accountslimit: e.target.value});
    handlebranchnameChange=(e) => this.setState({ branchname: e.target.value});
    handleentitytypeChange= (e) => this.setState({ entitytype: e});
    
    handleCompanyPhonetwoChange= (e) => this.setState({ companyphonetwo: e});
    handleCompanyPhonethreeChange= (e) => this.setState({ companyphonethree: e});
    handlecompanydealershipChange= (e) => this.setState({ companydealership: e.target.value});
    handlewebsiteChange= (e) => this.setState({ website: e.target.value});
    handleappbannerChange= (e) => this.setState({ appbanner: e.target.files[0]});
    handlevendorpaymentvalueChange=(e)=>this.setState({vendorpaymentvalue:e.target.value})
    
    handledeliveryagencyChange= (e) => this.setState({ deliveryagency: e});
  
    handleAddressChange= (e) => this.setState({ address: e.target.value});
    handleretainedearningsChange= (e) => this.setState({ retainedearnings: e.target.value});
    handlesharevalueChange= (e) => this.setState({ sharevalue: e.target.value});
    handlesafebalanceChange= (e) => this.setState({ safebalance: e.target.value});
    handleroundoffvalueChange= (e) => this.setState({ roundoffvalue: e.target.value});
    handlesharetransferchargeChange= (e) => this.setState({ sharetransfercharge: e.target.value});
    handlesmsmessagesChange= (e) => this.setState({ smsmessages: e.target.value});
    handledataidentificationcodeChange= (e) => this.setState({ dataidentificationcode: e.target.value});



    handleautomatedloanrepaymentchange=(value)=>{
        //alert(this.state.usesms)
        this.setState({automatedloanrepayment:!this.state.automatedloanrepayment})
    }

    handlesmsonautomatedloanrepaymentchange=(value)=>{
        //alert(this.state.usesms)
        this.setState({smsonautomatedloanrepayment:!this.state.smsonautomatedloanrepayment})
    }

    handleautomatedloanreminderschange=(value)=>{
        //alert(this.state.usesms)
        this.setState({automatedloanreminders:!this.state.automatedloanreminders})
    }

    handleloanrepaymentalertdaysChange= (e) => this.setState({ loanrepaymentalertdays: e.target.value});


    handlesmschange=(value)=>{
        //alert(this.state.usesms)
        this.setState({usesms:!this.state.usesms})
    }


    handleshowwelfarechange=(value)=>{
        //alert(this.state.usesms)
        this.setState({showwelfare:!this.state.showwelfare})
    }


    handleshowuniversitychange=(value)=>{
        //alert(this.state.usesms)
        this.setState({showuniversity:!this.state.showuniversity})
    }

    handleshowlegacychange=(value)=>{
        //alert(this.state.usesms)
        this.setState({showlegacy:!this.state.showlegacy})
    }

    handleshowemergencychange=(value)=>{
        //alert(this.state.usesms)
        this.setState({showemergency:!this.state.showemergency})
    }

    handleshowdreamchange=(value)=>{
        //alert(this.state.usesms)
        this.setState({showdream:!this.state.showdream})
    }

    handleshowchildrenchange=(value)=>{
        //alert(this.state.usesms)
        this.setState({showchildren:!this.state.showchildren})
    }

    handleshowsubaccounttransferchange=(value)=>{
        //alert(this.state.usesms)
        this.setState({showsubaccounttransfer:!this.state.showsubaccounttransfer})
    }

    handleshowgroupaccountschange=(value)=>{
        //alert(this.state.usesms)
        this.setState({showgroupaccounts:!this.state.showgroupaccounts})
    }


    handleshoweditablewithdrawchargechange=(value)=>{
        //alert(this.state.usesms)
        this.setState({showeditablewithdrawcharge:!this.state.showeditablewithdrawcharge})
    }

    handlesharesfeaturechange=(value)=>{
        //alert(this.state.usesms)
        this.setState({sharesfeature:!this.state.sharesfeature})
    }

    handleuserbalancesfeaturechange=(value)=>{
        //alert(this.state.usesms)
        this.setState({userbalancesfeature:!this.state.userbalancesfeature})
    }

    handleinterbranchtransactionsactivatedchange=(value)=>{
        //alert(this.state.usesms)
        this.setState({interbranchtransactionsactivated:!this.state.interbranchtransactionsactivated})
    }

    handlepackageChange= (e) => this.setState({ package: e});
    handlemobilemoneygatewaycodeChange= (e) => this.setState({ mobilemoneygatewaycode: e.target.value});


    //submit button pressed
    handleFormSubmit=() =>{
        const saccoId=this.props.match.params.saccoId;

        this.setState({datarequested:true})

        let form_data = new FormData();
        form_data.append('sacco_name', this.state.companyname);
        form_data.append('branchname', this.state.branchname);
        form_data.append('company_email', this.state.companyemail);
        form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
        form_data.append('box_number', this.state.boxnumber);
        form_data.append('licencedays', this.state.licence);
        form_data.append('accountslimit',this.state.accountslimit);
        this.state.companylogo==null?
    
        console.log("No profile file")
        :
        form_data.append('sacco_logo', this.state.companylogo,this.state.companylogo?this.state.companylogo.name:"");
        
        form_data.append('website',this.state.website);
        form_data.append('RegisteredPhoneNumber2', this.state.companyphonetwo);
        form_data.append('RegisteredPhoneNumber3', this.state.companyphonethree);
        form_data.append('usesms', this.state.usesms);

        form_data.append('safe_balance', this.state.safebalance);
        form_data.append('sharevalue', this.state.sharevalue);

        form_data.append('sharecapital', this.state.sharecapital);
        form_data.append('noofshares', this.state.noofshares);
        form_data.append('dividend_sharable_percentage',this.state.dividend_sharable_percentage);

        form_data.append('retained_earnings', this.state.retainedearnings);
        form_data.append('activated', this.state.activated);
        form_data.append('address',this.state.address);
        form_data.append('package',this.state.package);
        form_data.append('roundoffvalue',this.state.roundoffvalue);

        form_data.append('showdream', this.state.showdream);
        form_data.append('showchildren', this.state.showchildren);
        form_data.append('showemergency', this.state.showemergency);
        form_data.append('showlegacy', this.state.showlegacy);
        form_data.append('showuniversity', this.state.showuniversity);
        form_data.append('showwelfare', this.state.showwelfare);
        form_data.append('showsubaccounttransfer', this.state.showsubaccounttransfer);

        form_data.append('sharetransfercharge', this.state.sharetransfercharge);
        form_data.append('smsmessages', this.state.smsmessages);

        form_data.append('automatedloanrepayment', this.state.automatedloanrepayment);
        form_data.append('smsonautomatedloanrepayment', this.state.smsonautomatedloanrepayment);
        form_data.append('loanrepaymentalertdays', this.state.loanrepaymentalertdays);
        form_data.append('automatedloanreminders', this.state.automatedloanreminders);
        form_data.append('dataidentificationcode',"SC"+moment().format(dateFormat).toString() );
        form_data.append('showgroupaccounts', this.state.showgroupaccounts);

        form_data.append('showeditablewithdrawcharge', this.state.showeditablewithdrawcharge);
        form_data.append('sharesfeature', this.state.sharesfeature);
        form_data.append('userbalancesfeature', this.state.userbalancesfeature);
        form_data.append('interbranchtransactionsactivated', this.state.interbranchtransactionsactivated);
        form_data.append('mobilemoneygatewaycode',this.state.mobilemoneygatewaycode);

        form_data.append('email_statements',this.state.email_statements);
        form_data.append('attach_officer_to_loan',this.state.attach_officer_to_loan);

        form_data.append('automated_accounts',this.state.automated_accounts);
        form_data.append('account_concstring',this.state.account_concstring);
        form_data.append('last_accountnmumber',this.state.last_accountnmumber);

        form_data.append('show_daily_loan_schedule',this.state.show_daily_loan_schedule);
        form_data.append('exclude_sundays_on_daily_loan_schedule',this.state.exclude_sundays_on_daily_loan_schedule);
        form_data.append('exclude_saturdays_on_daily_loan_schedule',this.state.exclude_saturdays_on_daily_loan_schedule);

        form_data.append('automated_dailyloanrepaymentondeposit',this.state.automated_dailyloanrepaymentondeposit);

        form_data.append('change_sharevalue',this.state.change_sharevalue);
        form_data.append('increase_in_no_ofshares',this.state.increase_in_no_ofshares);
        form_data.append('restrictuser_to_entered_accounts_app',this.state.restrictuser_to_entered_accounts_app);

        form_data.append('salary_requisition_allowed',this.state.salary_requisition_allowed);
        form_data.append('allow_transaction_editing',this.state.allow_transaction_editing);


        axios.put(serverconfig.backendserverurl+`/api/saccos/${saccoId}/`, form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then(res =>{
            this.setState({datarequested:false})
            message.info('Successfully updated sacco')
            this.componentmounttasks();
        }
        )
        .catch(error => console.log(error))

    }


    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
          }else{
            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="6">
                    <Card title={<h3 style={{fontWeight:'bolder'}}>{this.state.profile.sacco_name}</h3> }>
                        <p>Email: {this.state.profile.company_email}</p>
                        <p>Phone: {this.state.profile.RegisteredPhoneNumber1}</p>
                        <p>Phone 2: {this.state.companyphonetwo}</p>
                        <p>Website: {this.state.profile.website}</p>
                        <p>Box No: {this.state.profile.box_number}</p>
                        <p>Accounts Limit: {this.state.profile.accountslimit}</p>
                        <p>Office Address: {this.state.profile.address}</p>
                        <p>Branch Name: {this.state.profile.branchname}</p>
                        <p>Licence Days: {this.state.profile.licencedays}</p>
                        <p>Safe Balance: {this.state.profile.safe_balance}</p>
                        <p>Retained Earnings: {this.state.profile.retained_earnings}</p>
                        <p>Package Name: {this.state.profile.packagename}</p>
                        <p>Round Off Value: {this.state.profile.roundoffvalue}</p>
                        <p>Share Value: {this.state.profile.sharevalue}</p>
                        <p>Share Transfer Charge: {this.state.profile.sharetransfercharge}</p>
                        <p>SMS balance: {this.state.profile.smsmessages} Messages</p>
                        <p>Data IdentificationCode: {this.state.profile.dataidentificationcode}</p>
                        <p>Mobile Money GateWay Code: {this.state.profile.mobilemoneygatewaycode}</p>

                        {
                            this.state.usesms?
                            <p>SMS Active: Yes</p>
                            :
                            <p>SMS Active: No</p>
    
                        }

                    </Card>
                    </Col>
    
                    <Col xs="12" sm="6" lg="6">
                      <Card 
                            style={{padding:10,alignContent:'center',alignItems:'center'}}
                            hoverable
                            cover={
                            
                                <Image
                                width={100}
                                //height={'50%'}
                                src={this.state.profile.sacco_logo}    
                                />                   
                             }
                        >
                        </Card>


                   
                    </Col>
                   
    
                    </Row>
                    <br/>
    
                    <Row>
                    <Col xs="12" sm="6" lg="6">
                    <Card 
                            style={{padding:10}}
                            hoverable>
    
                        <h4>Sacco Profile Update</h4>
                        <Form
                            >
                                <FormItem label="Sacco Name"
                   
                                >
                                <Input name="sacconame"  placeholder="Put a name here." value={this.state.companyname} onChange={this.handleCompanyNameChange} />
                                </FormItem>
                    
                    
                                <FormItem label="Branch Name"
                        
                                >
                                <Input  placeholder="Put a name here." value={this.state.branchname} onChange={this.handlebranchnameChange} />
                                </FormItem>
                    
                                <FormItem label="Sacco Logo">
                                <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo"  onChange={this.handleCompanyLogoChange}/>
                                </FormItem>  
                                
                                <FormItem label="Sacco Email"
                 
                                >
                                <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.companyemail} onChange={this.handleCompanyEmailChange} />
                                </FormItem> 
                    
                                <FormItem label="Phone Number">
                                <PhoneInput
                                    countrySelectProps={{ unicodeFlags: true }}
                                    defaultCountry="UG"
                                    placeholder="Enter phone number"
                                    value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
                                </FormItem>
                                <FormItem label="Phone Number Two">
                                <PhoneInput
                                    countrySelectProps={{ unicodeFlags: true }}
                                    defaultCountry="UG"
                                    placeholder="Enter phone number"
                                    value={this.state.companyphonetwo} onChange={this.handleCompanyPhonetwoChange}/>
                                </FormItem> 
                    
                                <FormItem label="Phone Number Three">
                                <PhoneInput
                                    countrySelectProps={{ unicodeFlags: true }}
                                    defaultCountry="UG"
                                    placeholder="Enter phone number"
                                    value={this.state.companyphonethree} onChange={this.handleCompanyPhonethreeChange}/>
                                </FormItem>  
                                <FormItem label="Website">
                                <Input  name="website"  placeholder="Webiste here" value={this.state.website} onChange={this.handlewebsiteChange} />
                                </FormItem>
                    
                                <FormItem label="Box number">
                                <Input name="boxnumber"  placeholder="Box number here" value={this.state.boxnumber} onChange={this.handleCompanyBoxNumberChange} />
                                </FormItem>
                    
                                <FormItem label="Address">
                                <Input name="address"  placeholder="Address here" value={this.state.address} onChange={this.handleAddressChange} />
                                </FormItem>

                                <FormItem label="Licence"
                          
                                >
                                <Input name="licence"  placeholder="Licence days" value={this.state.licence} onChange={this.handleLicenceChange} />
                                </FormItem>
                    
                                <FormItem name="smsactive" label="SMS Active" >
                                    <Switch
                                        checked={this.state.usesms}
                                        onChange={val=>{this.handlesmschange(val)}}
                                        checkedChildren="ON"
                                        unCheckedChildren="OFF"
                                        />
                                
                                </FormItem>

                                <FormItem label="SMS Balance"
                          
                                >
                                <Input name="SMS Messages"  placeholder="SMS Messages" value={this.state.smsmessages} onChange={this.handlesmsmessagesChange} />
                                </FormItem>



                                <FormItem label="Accounts Limit"
                               
                                >
                                <Input name="accountslimit"  placeholder="Accounts Limit" value={this.state.accountslimit} onChange={this.handleaccountslimitChange} />
                                </FormItem>
                    
                                <FormItem label="Safe Balance"
                          
                                >
                                <Input name="safebalance"  placeholder="Safe Balance" value={this.state.safebalance} onChange={this.handlesafebalanceChange} />
                                </FormItem>

                                <FormItem label="Share Value"
                           
                                >
                                <Input name="sharevalue"  placeholder="Share Value" value={this.state.sharevalue} onChange={this.handlesharevalueChange} />
                                </FormItem>    

                                <FormItem label="Share Capital"
            
                                >
                                <Input name="sharecapital"  placeholder="Share Capital" value={this.state.sharecapital} onChange={(val)=>{this.setState({sharecapital:val.target.value})}} />
                                </FormItem>    

                                <FormItem label="No Of Shares"
            
                                >
                                <Input name="noofshares"  placeholder="No Of Shares" value={this.state.noofshares} onChange={(val)=>{this.setState({noofshares:val.target.value})}} />
                                </FormItem>  

                                <FormItem label="Dividends Sharable Percentage"
            
                                >
                                <Input name="dividend_sharable_percentage"  placeholder="Dividends Sharable Percentage" value={this.state.dividend_sharable_percentage} onChange={(val)=>{this.setState({dividend_sharable_percentage:val.target.value})}} />
                                </FormItem> 

                                <FormItem label="Retained Earnings"
                             
                                >
                                <Input name="retained_earnings"  placeholder="Retained Earnings" value={this.state.retainedearnings} onChange={this.handleretainedearningsChange} />
                                </FormItem>

                                <FormItem label="Package">
                                    <Select placeholder="Package"  value={this.state.package} onChange={this.handlepackageChange} >
                                    {this.state.packages.map(
                                        (pckg)=>(
                                        <Option value={pckg.id}>{pckg.package_name}</Option>
                                        ))}
                                    </Select>
                                </FormItem>

                                <FormItem label="Round Off Value">
                                    <Input name="roundoffvalue"  placeholder="Round Off Value" value={this.state.roundoffvalue} onChange={this.handleroundoffvalueChange} />
                                </FormItem>

                                <FormItem label="Share Transfer Charge">
                                    <Input name="sharetransfercharge"  placeholder="Share Transfer Charge" value={this.state.sharetransfercharge} onChange={this.handlesharetransferchargeChange} />
                                </FormItem>
                            <h4>Sub Account System features section</h4>

                            <FormItem name="showwelfare" label="Show welfare account" >
                                <Switch
                                    checked={this.state.showwelfare}
                                    onChange={val=>{this.handleshowwelfarechange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem name="showemergency" label="Show emergency account" >
                                <Switch
                                    checked={this.state.showemergency}
                                    onChange={val=>{this.handleshowemergencychange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem name="showdream" label="Show dream account" >
                                <Switch
                                    checked={this.state.showdream}
                                    onChange={val=>{this.handleshowdreamchange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem name="showlegacy" label="Show legacy account" >
                                <Switch
                                    checked={this.state.showlegacy}
                                    onChange={val=>{this.handleshowlegacychange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem name="showchildren" label="Show children account" >
                                <Switch
                                    checked={this.state.showchildren}
                                    onChange={val=>{this.handleshowchildrenchange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem name="showuniversity" label="Show University account" >
                                <Switch
                                    checked={this.state.showuniversity}
                                    onChange={val=>{this.handleshowuniversitychange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>


                            <FormItem name="showsubaccounttransfer" label="Show Sub Account Transfer" >
                                <Switch
                                    checked={this.state.showsubaccounttransfer}
                                    onChange={val=>{this.handleshowsubaccounttransferchange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                            </FormItem>


                            <h4>Loan System features section</h4>
                            <FormItem name="automatedloanrepayment" label="Automated Loan Repayment" >
                                <Switch
                                    checked={this.state.automatedloanrepayment}
                                    onChange={val=>{this.handleautomatedloanrepaymentchange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem name="smsonautomatedloanrepayment" label="Send SMs on Automated Loan repayments" >
                                <Switch
                                    checked={this.state.smsonautomatedloanrepayment}
                                    onChange={val=>{this.handlesmsonautomatedloanrepaymentchange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem name="automatedloanreminders" label="Automated Loan Reminders" >
                                <Switch
                                    checked={this.state.automatedloanreminders}
                                    onChange={val=>{this.handleautomatedloanreminderschange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem label="Loan Reminder Days">
                                    <Input name="loanrepaymentalertdays"  placeholder="Loan Reminder days" value={this.state.loanrepaymentalertdays} onChange={this.handleloanrepaymentalertdaysChange} />
                            </FormItem>

                            <FormItem name="showgroupaccounts" label="Show Group Accounts" >
                                <Switch
                                    checked={this.state.showgroupaccounts}
                                    onChange={val=>{this.handleshowgroupaccountschange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>
                            

                            <FormItem name="showeditablewithdrawcharge" label="Show Editable Withdraw charge" >
                                <Switch
                                    checked={this.state.showeditablewithdrawcharge}
                                    onChange={val=>{this.handleshoweditablewithdrawchargechange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem name="sharesfeature" label="Shares Feature" >
                                <Switch
                                    checked={this.state.sharesfeature}
                                    onChange={val=>{this.handlesharesfeaturechange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem name="userbalancesfeature" label="User Balances Feature" >
                                <Switch
                                    checked={this.state.userbalancesfeature}
                                    onChange={val=>{this.handleuserbalancesfeaturechange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem label="Mobile Money Gateway Code">
                            <Input name="mmcode"  placeholder="Gate way Code" value={this.state.mobilemoneygatewaycode} onChange={this.handlemobilemoneygatewaycodeChange} />
                            </FormItem>

                            <FormItem name="interbranchtransactionsactivated" label="Interbranch Transactions Feature" >
                                <Switch
                                    checked={this.state.interbranchtransactionsactivated}
                                    onChange={val=>{this.handleinterbranchtransactionsactivatedchange(val)}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem name="Emailstatements" label="Email Monthly Financial Statements" >
                                <Switch
                                    checked={this.state.email_statements}
                                    onChange={val=>{this.setState({email_statements:!this.state.email_statements})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem name="attach_officer_to_loan" label="Attach Loan Officer" >
                                <Switch
                                    checked={this.state.attach_officer_to_loan}
                                    onChange={val=>{this.setState({attach_officer_to_loan:!this.state.attach_officer_to_loan})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                            </FormItem>

                            <FormItem name="automated_accounts" label="Automated Accounts?" >
                                <Switch
                                    checked={this.state.automated_accounts}
                                    onChange={val=>{this.setState({automated_accounts:!this.state.automated_accounts})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
     
                            </FormItem>


                            {
                              this.state.automated_accounts===true?
                              <FormItem label="Account Concatination String">
                              <Input name="account_concstring"  placeholder="Account Concatination String" value={this.state.account_concstring} onChange={(val)=>{this.setState({account_concstring:val.target.value})}} />
                              </FormItem>
                              :
                              null
                            }


                            <FormItem name="show_daily_loan_schedule" label="Daily Loan Schedule?" >
                                <Switch
                                    checked={this.state.show_daily_loan_schedule}
                                    onChange={val=>{this.setState({show_daily_loan_schedule:!this.state.show_daily_loan_schedule})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                            </FormItem>

                            <FormItem name="exclude_sundays_on_daily_loan_schedule" label="Exclude Sundays from daily schedule?" >
                                <Switch
                                    checked={this.state.exclude_sundays_on_daily_loan_schedule}
                                    onChange={val=>{this.setState({exclude_sundays_on_daily_loan_schedule:!this.state.exclude_sundays_on_daily_loan_schedule})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                            </FormItem>


                            <FormItem name="exclude_saturdays_on_daily_loan_schedule" label="Exclude Saturdays from daily schedule?" >
                                <Switch
                                    checked={this.state.exclude_saturdays_on_daily_loan_schedule}
                                    onChange={val=>{this.setState({exclude_saturdays_on_daily_loan_schedule:!this.state.exclude_saturdays_on_daily_loan_schedule})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                            </FormItem>

                            <FormItem name="automated_dailyloanrepaymentondeposit" label="Automated daily loan repayment on deposit?" >
                                <Switch
                                    checked={this.state.automated_dailyloanrepaymentondeposit}
                                    onChange={val=>{this.setState({automated_dailyloanrepaymentondeposit:!this.state.automated_dailyloanrepaymentondeposit})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                            </FormItem>

                            <FormItem name="increase_in_no_ofshares" label="An increase in shares on share purchase?" >
                                <Switch
                                    checked={this.state.increase_in_no_ofshares}
                                    onChange={val=>{this.setState({increase_in_no_ofshares:!this.state.increase_in_no_ofshares})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                            </FormItem>

                            <FormItem name="change_sharevalue" label="Change in share value on share purchase?" >
                                <Switch
                                    checked={this.state.change_sharevalue}
                                    onChange={val=>{this.setState({change_sharevalue:!this.state.change_sharevalue})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                            </FormItem>                            

                            <FormItem name="restrictuser_to_entered_accounts_app" label="Restrict User to Only entered Accounts?" >
                                <Switch
                                    checked={this.state.restrictuser_to_entered_accounts_app}
                                    onChange={val=>{this.setState({restrictuser_to_entered_accounts_app:!this.state.restrictuser_to_entered_accounts_app})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                            </FormItem>  

                            <FormItem name="salary_requisition_allowed" label="Allow Salary Requisition?" >
                                <Switch
                                    checked={this.state.salary_requisition_allowed}
                                    onChange={val=>{this.setState({salary_requisition_allowed:!this.state.salary_requisition_allowed})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                            </FormItem>  

                            <FormItem name="allow_transaction_editing" label="Allow Transactions Editing?" >
                                <Switch
                                    checked={this.state.allow_transaction_editing}
                                    onChange={val=>{this.setState({allow_transaction_editing:!this.state.allow_transaction_editing})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                            </FormItem>  

                            <FormItem>
                            <Button type="primary" htmlType="button" onClick={(event) => this.handleFormSubmit()}>Update</Button>
                            </FormItem>
                            </Form>
                    </Card>
                       
                    </Col>
                
                    </Row>
    
                    <br/>
    
                </div>
            )

          }
     
    }
}

export default SaccoDetail; 