import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Spin,Popconfirm,message,Tooltip,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined,MehFilled,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../views/serverconn'
import Packagecreateform from '../components/packagecreateform'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

var token= ''


class ReceiptToPrint extends React.Component {


  state = {
    companyprofile:{},
    companystatus:false
  };

  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile:res.data
        })

    })

  }




  render() {
    return (
      <div style={{padding:20}}>
          <Row > 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
              
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.company_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
                </Col>
           </Row>
          <br></br>
          <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><strong>{"AVAILABLE PACKAGES"}</strong></h3>

          <Table columns={this.props.columns} 
          pagination={{hideOnSinglePage:true,responsive:true}} 
          dataSource={this.props.companymanagers}  
          bordered/>
         
      </div>
    );
  }
}

class PackageList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    packages: [],
    companymanagers:[],
    datarequested:true,
  };

      //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    //Now submit sale data to database
    axios.delete(serverconfig.backendserverurl+`/api/packages/${id}`)
    .then(res =>window.location.reload(false),message.info('Successfully deleted package')
    )
    .catch(error => console.log(error)) 
  };


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }


    axios.get(serverconfig.backendserverurl+'/api/packages/')
    .then(res => {
        this.setState({
          packages:res.data
        })

        this.setState({datarequested:false});
    })


}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [
      
      {
        title: 'Name',
        dataIndex: 'package_name',
        key: 'id',
        ...this.getColumnSearchProps('package_name'),
      },
      {
        title: 'User Account Limit',
        dataIndex: 'useraccountslimit',
        key: 'id',
      },
      {
        title: 'Member Accounts Limit',
        dataIndex: 'memberaccountslimit',
        key: 'id',
      },
      {
        title: 'Loans Limit',
        dataIndex: 'loanslimit',
        key: 'id',
      },
      {
        title: 'Package Cost Per Month',
        dataIndex: 'packagecostpermonth',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },      
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/packages/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete package</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
    
        </p>,
      },

    ];

    const printcolumns = [
      {
        title: 'Name',
        dataIndex: 'package_name',
        key: 'id',
        ...this.getColumnSearchProps('package_name'),
      },
      {
        title: 'User Account Limit',
        dataIndex: 'useraccountslimit',
        key: 'id',
      },
      {
        title: 'Member Accounts Limit',
        dataIndex: 'memberaccountslimit',
        key: 'id',
      },
      {
        title: 'Loans Limit',
        dataIndex: 'loanslimit',
        key: 'id',
      },
      {
        title: 'Package Cost Per Month',
        dataIndex: 'packagecostpermonth',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
    ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="PACKAGES" key="1">
            <Table 
            columns={columns}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.packages}
            scroll={{ x: 1000 }}
            bordered/>
            </Panel>
            </Collapse>
            <br />

 
      
            <Row > 
              <Col xs="12" sm="6" lg="6">
                <Collapse defaultActiveKey={['0']} onChange={callback}>
                <Panel header="Collapse Panel to open package creation Form" key="1">
                  <h2>Create Package here</h2>
                  <Packagecreateform />
                </Panel>
                </Collapse>

              </Col>
   
             
              <Col xs="12" sm="6" lg="6">
                <a>
                <ReactToPrint
                  trigger={() =>                     
                    <div>
                      <Tooltip title="Click to print all packages" placement="bottom">
                      <p ><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                      <h6 >Print</h6>
                      </Tooltip>
                    </div>                          
                    }
                    content={() => this.componentRef}
                  /> 
                </a>

              </Col>
                       
          </Row>
          
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            columns={printcolumns}
            companymanagers={this.state.packages}
            ref={el => (this.componentRef = el)} /> 
             </div>

        </div>
    )

    }

  
  }
}

export default PackageList; 

