import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Avatar,Spin,Image,Modal,Input,message,Select,Checkbox,Switch } from 'antd';
import CompanyProfileForm from '../components/CompanyProfileForm'
import { UserOutlined } from '@ant-design/icons';
import { PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import ImgsViewer from 'react-images-viewer'
import PhoneInput from 'react-phone-number-input'
import * as serverconfig from '../../views/serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';

const FormItem=Form.Item;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

var token= ''


class PackageDetail extends React.Component{

    state={
        packagename:'',  
        useraccountslimit:0,  
        memberaccountslimit:0,  
        loanslimit:0,  
        packagecostpermonth:0,  
        profile:{}
    }


    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
      }else{
          token= ''
      }


        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }

        const packageId=this.props.match.params.packageId;
        axios.get(`${serverconfig.backendserverurl}/api/packages/${packageId}`)
        .then(res => {  
            this.setState({
                profile: res.data
            })

            this.setState({packagename:res.data.package_name})
            this.setState({useraccountslimit:res.data.useraccountslimit})
            this.setState({memberaccountslimit:res.data.memberaccountslimit})
            this.setState({loanslimit:res.data.loanslimit})
            this.setState({packagecostpermonth:res.data.packagecostpermonth})

        })

    }


    handlepackagenameChange= (e) => this.setState({ packagename: e.target.value});
  handleuseraccountslimitChange= (e) => this.setState({ useraccountslimit: e.target.value});
  handlememberaccountslimitChange= (e) => this.setState({ memberaccountslimit: e.target.value});
  handleloanslimitChange= (e) => this.setState({ loanslimit: e.target.value});
  handlepackagecostpermonthChange= (e) => this.setState({ packagecostpermonth: e.target.value});


    //submit button pressed
    handleFormSubmit=() =>{
        const packageId=this.props.match.params.packageId;

        this.setState({datarequested:true})

        let form_data = new FormData();
        form_data.append('package_name', this.state.packagename);
        form_data.append('useraccountslimit', this.state.useraccountslimit);
        form_data.append('memberaccountslimit', this.state.memberaccountslimit);
        form_data.append('loanslimit', this.state.loanslimit);
        form_data.append('packagecostpermonth', this.state.packagecostpermonth);
    
          axios.put(serverconfig.backendserverurl+`/api/packages/${packageId}/`, form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res =>        this.setState({datarequested:false})
        , window.location.reload(false),message.info('Successfully updated package')
        )
        .catch(error => console.log(error))

    }


    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
          }else{
            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card title={this.state.profile.package_name}>
                        <p>User Accounts Limit: {this.state.profile.useraccountslimit}</p>
                        <p>Member Accounts Limit: {this.state.profile.memberaccountslimit}</p>
                        <p>Loans Limit: {this.state.loanslimit}</p>
                        <p>Package Cost Per Month: {this.state.profile.packagecostpermonth}</p>

                    </Card>
                    </Col>
    
                    <Col xs="12" sm="6" lg="4">
                    <Card title={''}>
                    <h4>Package Profile Update</h4>

                    <Form>
            <FormItem label="Package Name">
              <Input name="packagename"  placeholder="Put a name here." value={this.state.packagename} onChange={this.handlepackagenameChange} />
            </FormItem>
  
  
          
            <FormItem label="User Accounts Limit"
 
            >
              <Input name="useraccountslimit"  placeholder="Accounts Limit" value={this.state.useraccountslimit} onChange={this.handleuseraccountslimitChange} />
            </FormItem>
  
            <FormItem label="Member Accounts Limit"
  
            >
              <Input name="memberaccountslimit"  placeholder="Member Accounts Limit" value={this.state.memberaccountslimit} onChange={this.handlememberaccountslimitChange} />
            </FormItem>

            <FormItem label="Loans Limit"
  
            >
              <Input name="loanslimit"  placeholder="Loans Limit" value={this.state.loanslimit} onChange={this.handleloanslimitChange} />
            </FormItem>


            <FormItem label="Package Cost Per Month"
 
            >
              <Input name="packagecostpermonth"  placeholder="Safe Balance" value={this.state.packagecostpermonth} onChange={this.handlepackagecostpermonthChange} />
            </FormItem>

                            <FormItem>
                            <Button type="primary" htmlType="button" onClick={(event) => this.handleFormSubmit()}>Update</Button>
                            </FormItem>
                            </Form>
                        </Card>

                    </Col>
                   
    
                    </Row>
                    <br/>
    
             
    
    
                </div>
            )

          }
     
    }
}

export default PackageDetail; 