import React from 'react';
import { Form, Input, Button,Select,message,Spin } from 'antd';
import { Table,Collapse,Popover,Popconfirm,Descriptions,Modal } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import { SearchOutlined,LoadingOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,EditOutlined } from '@ant-design/icons';

const dateFormat = 'HHmmssDDMMYYYY';

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''


class Packagecreateform extends React.Component {

  state = {
    packagename:'',  
    useraccountslimit:0,  
    memberaccountslimit:0,  
    loanslimit:0,  
    packagecostpermonth:0,  
  }

  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
    
   this.setState({datarequested:false})
  }

  handlepackagenameChange= (e) => this.setState({ packagename: e.target.value});
  handleuseraccountslimitChange= (e) => this.setState({ useraccountslimit: e.target.value});
  handlememberaccountslimitChange= (e) => this.setState({ memberaccountslimit: e.target.value});
  handleloanslimitChange= (e) => this.setState({ loanslimit: e.target.value});
  handlepackagecostpermonthChange= (e) => this.setState({ packagecostpermonth: e.target.value});


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('package_name', this.state.packagename);
    form_data.append('useraccountslimit', this.state.useraccountslimit);
    form_data.append('memberaccountslimit', this.state.memberaccountslimit);
    form_data.append('loanslimit', this.state.loanslimit);
    form_data.append('packagecostpermonth', this.state.packagecostpermonth);

    axios.post(serverconfig.backendserverurl+'/api/packages/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>        this.setState({datarequested:false})
  , window.location.reload(false),message.info('Successfully created package')
  )
  .catch(error => console.log(error))

  }

  


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div >
          <Form
          onFinish={(event) => this.handleFormSubmit(event)}
          >
            <FormItem label="Package Name"
                name="packagename"
                rules={[
                  {
                    required: true,
                    message: 'Please input name',
                  },
                ]}
              >
              <Input name="packagename"  placeholder="Put a name here." value={this.state.packagename} onChange={this.handlepackagenameChange} />
            </FormItem>
  
  
          
            <FormItem label="User Accounts Limit"
                  name="useraccountslimit"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter number',
                    },
                  ]}
            >
              <Input name="useraccountslimit"  placeholder="Accounts Limit" value={this.state.useraccountslimit} onChange={this.handleuseraccountslimitChange} />
            </FormItem>
  
            <FormItem label="Member Accounts Limit"
                  name="memberaccountslimit"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter number',
                    },
                  ]}
            >
              <Input name="memberaccountslimit"  placeholder="Member Accounts Limit" value={this.state.memberaccountslimit} onChange={this.handlememberaccountslimitChange} />
            </FormItem>

            <FormItem label="Loans Limit"
                  name="loanslimit"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter number',
                    },
                  ]}
            >
              <Input name="loanslimit"  placeholder="Loans Limit" value={this.state.loanslimit} onChange={this.handleloanslimitChange} />
            </FormItem>


            <FormItem label="Package Cost Per Month"
                  name="packagecostpermonth"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Amount',
                    },
                  ]}
            >
              <Input name="packagecostpermonth"  placeholder="Safe Balance" value={this.state.packagecostpermonth} onChange={this.handlepackagecostpermonthChange} />
            </FormItem>

         
            <FormItem>
              <Button type="primary" htmlType="submit" >Create</Button>
            </FormItem>
          </Form>
        </div>
      );
    }


   
  }

}


export default Packagecreateform;



